@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeicons/primeicons.css";

html,
body {
    height    : 100%;
    background: #f5f5f5;
}

nav.navbar {
    margin-bottom: 1rem;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.dropdown-input {
    display: block;
}

.floatRight {
    float: right;
}

.short,
.column.short {
    padding-top   : 0.25rem;
    padding-bottom: 0.25rem;
}

.narrow,
.column.narrow,
.p-datatable.p-datatable-sm .p-datatable-header {
    padding-right: 0rem;
    padding-left : 0rem;
}

.pi.pi-exclamation-triangle,
.pi.pi-exclamation-circle {
    color: red;
}

.p-button.p-button-rounded:not(.p-button-outlined):not(.p-button-plain) {
    background-color: rgb(13, 114, 126);
    border-color    : rgb(13, 114, 126);
}

.p-button.p-button-rounded:not(.p-button-outlined):not(.p-button-plain):enabled:hover {
    background-color: rgb(15, 139, 153);
    border-color    : rgb(15, 139, 153);
}

.stdColor .p-inputtext,
.p-button-text:not(.p-button-plain),
.important .p-button.p-button-text.stdColor,
.important .p-button.p-button-text.stdColor:enabled:hover,
.p-button-rounded.p-button-outlined.stdColor,
.p-button-rounded.p-button-outlined.stdColor:enabled:hover {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size  : 14px;
    color      : rgb(73, 80, 87);
}

.p-button.p-button-text:focus,
.important .p-button.p-button-text:focus,
tr>td>.p-button.p-button-text:focus {
    box-shadow: none;
}

.p-button.p-button-outlined,
.p-button.p-button-outlined:enabled:hover {
    border     : 2px solid;
    font-weight: 600;
}

body .myGadget .container a,
body .myGadget .container a strong {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size  : 14px;
    color      : rgb(73, 80, 87);
}

body .p-component,
body .myGadget .container .p-component,
body .myGadget .container .p-component strong {
    font-size: 14px;
}

.p-input-icon-left input {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
}

input.p-column-filter {
    padding: 0.5rem;
    width  : 100%;
}

.p-column-filter .p-inputnumber-input {
    width: 100%;
}

.p-column-filter.p-dropdown,
.p-column-filter.p-multiselect,

.p-column-filter>.p-treeselect {
    width: 100%;
}

.p-dropdown .p-dropdown-trigger,
.p-multiselect .p-multiselect-trigger {
    width: 2rem;
}

body .myGadget .container th {
    white-space: nowrap;
}

.collapsedContent {
    display : none;
    overflow: hidden;
}

.container .columns,
.modal-content .columns {
    margin: 0px;
}

.p-datatable .p-datatable-header {
    background-color: white;
    border-top      : 0px;
}

.p-datatable .p-datatable-thead>tr:not(.notAHeader)>th,
.p-datatable .p-sortable-column.p-highlight {
    background-color: rgb(13, 114, 126);
    color           : white;
}

.p-datatable .p-datatable-thead>tr.notAHeader>th {
    background-color: white;
}

.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: white;
}

.p-datatable .p-sortable-column:hover,
.p-datatable .p-sortable-column:hover .p-sortable-column-icon,
.p-datatable .p-sortable-column:not(.p-highlight):hover,
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:hover,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    background-color: rgb(15, 139, 153);
    color           : white;
}

.p-treetable .p-treetable-header {
    background-color: white;
    border-top      : 0px;
}

.p-treetable .p-treetable-thead>tr:not(.notAHeader)>th,
.p-treetable .p-sortable-column.p-highlight {
    background-color: rgb(13, 114, 126);
    color           : white;
}

.p-treetable .p-treetable-thead>tr.notAHeader>th {
    background-color: white;
}

.p-treetable .p-sortable-column .p-sortable-column-icon,
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: white;
}

.p-treetable .p-sortable-column:hover,
.p-treetable .p-sortable-column:hover .p-sortable-column-icon,
.p-treetable .p-sortable-column:not(.p-highlight):hover,
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon,
.p-treetable .p-sortable-column.p-highlight:hover,
.p-treetable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    background-color: rgb(15, 139, 153);
    color           : white;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: rgb(13, 114, 126);
    border-color    : rgb(13, 114, 126);
    color           : white;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    color: rgb(13, 114, 126);
}

.tooltip-container {
    display : inline-block;
    position: relative
}

.p-tooltip-text {
    width: 600px;
}

.tooltip-narrow .p-tooltip-text {
    width: 250px;
}

.tooltip-super-narrow .p-tooltip-text {
    width: 180px;
}

.tooltip-wide .p-tooltip-text {
    width: 800px;
}

.info-label {
    font-weight: 400;
}

.additionalInfo {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size  : 14px;
    color      : rgb(73, 80, 87);
}

.additionalInfo strong {
    font-weight: 600;
}

button.p-button.no-padding {
    padding: 0;
}

.fill {
    -webkit-box-sizing: border-box;
    -moz-box-sizing   : border-box;
    box-sizing        : border-box;
    width             : 100%
}

a.link,
body .myGadget .container a.link,
body .myGadget .container .p-button-text.link,
body .myGadget .container .p-button-text.link:enabled:hover {
    color      : rgb(21, 119, 130);
    font-weight: 600;
}

a.link:hover {
    text-decoration: underline;
}

@media screen and (min-width: 769px) {
    .modal-content {
        margin: 0 20px !important;
    }
}

#scannerViewPort {
    position: relative;
}

canvas.drawing,
canvas.drawingBuffer {
    position: absolute;
    left    : 0;
    top     : 0;
}

.hidden {
    display: none;
}

html,
body {
    height: 100%;
}

body {
    margin     : 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

th,
td {
    overflow-wrap: break-word;
}

.sticky-bottom {
    position        : sticky;
    bottom          : 0;
    padding-bottom  : 12px;
    overflow        : hidden;
    /* div container would have 0 height when containing only flex elements */
    background-color: #fff;
}

.loading-noscroll {
    overflow: hidden;
}

.p-datatable .p-datatable-tbody>tr.redText,
.p-button.p-button-text.no-padding.redText,
.redText {
    color: red;
}

.tealText {
    color: rgb(13, 114, 126);
}

.p-datatable .p-datatable-tbody>tr>td.center-text {
    text-align: center;
}

.has-line-through {
    text-decoration: line-through;
}

div.joyride-step__title {
    width: 100%;
    color:rgb(13, 114, 126) !important;
    border-bottom: 1px solid rgb(13, 114, 126);
    padding-bottom: 0.5rem;
}

div.joyride-step__counter {
    color: rgb(13, 114, 126);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
